import type BaseGridControl from 'o365.controls.Grid.BaseGrid.ts';

/** Abstract grid extension class */
export default abstract class BaseGridExtension<T> {
    /** Extension module name */
    static __extension = 'none';

    protected _getGridControl: ()=>T;

    constructor(gridControl: T) {
        this._getGridControl = () => gridControl;
    }

    /** Initialize extension. Implement this method instead of overriding the constructor */
    protected abstract initializeExtension(): void;

    /** Construct and assign extension to BaseGridControl */
    static extend<T extends BaseGridExtension<K>, K extends BaseGridControl = BaseGridControl>(this: new (gridControl: K) => T, gridControl: K): T {
        if (this['__extension'] === 'none' || this['__extension'] == null) {
            if (this.name === 'BaseGridExtension') {
                throw new TypeError(`BaseGridExtension is an abstract class which can't have instances`);
            } else {
                throw new TypeError(`${this.name} extension is missing static override for __extension property`);
            }
        }

        if (!gridControl[this['__extension']]) {
            const extension = new this(gridControl);
            extension.initializeExtension();
            gridControl[this['__extension']] = extension;
        } 
        return gridControl[this['__extension']];
    }
}
